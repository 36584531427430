import {EQLGame, Frontend} from './eql'

export default class NeoGames implements Frontend {
    code: string = "NEOGAMES";
    events: string[] = [
        "gameLoaded",
        "gameRoundStarted",
        "gameRoundEnded",
        "balanceUpdated",
        "gameError",
        "MovetoMoney",
        "majorPrize",
        "SetVolume",
        "LogoutSanityCheck",
        "HistorySanityCheck",
        "ReloadGame",
        "ShowContactUs",
        "CloseGame",
    ];

    SendClientEvent = (eventName: string, gameId: string|null, languageCode: string, data?: any) => {
        if(this.events.includes(eventName)) {
            let messageObject:any = { 
                type: eventName,
            };

            if (data !== null && data !== undefined) { 
                messageObject.value = data; 
            }

            window.parent.postMessage(messageObject, '*');
        }
    }

    ApplyLaunchParams = (game: EQLGame):EQLGame => {
        const queryParams = new URLSearchParams(window.location.search);

        game.PLATFORM = queryParams.get("Platform"); 

       //queryParams.get("SanityCheckLimit") - might want to use this to reduce reality start by this amount
       //queryParams.get("FreeGameDetails") - FreeGameDetailes???
       //queryParams.get("StartSound") 

        const realityCheckIntervalString = queryParams.get("SanityCheck")
        if(realityCheckIntervalString) {
            let realityCheckInterval = Number.parseInt(realityCheckIntervalString)
            game.STATE.realityCheckInterval = realityCheckInterval;
        }

        return game;
    }
}